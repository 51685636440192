.c3-texts {
  color: #000;
}
.c3-chart-arc.c3-target g path {
  stroke: none;
}
.custom-tooltip {
  background-color: #514a5c;
  color: $white;
  white-space: nowrap;
  padding: 5px;
  border-radius: 3px;
}
