.modal-content {
  border: none;
  border-radius: 20px;
  color: white;
  text-align: center;
	background: rgb(51, 44, 63);


  .modal-header {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom: none !important;
    text-align: center !important;
    justify-content: center;
    background: rgb(51, 44, 63);
  }

  .modal-body {
    background: rgb(51, 44, 63);
    min-height: 200px;

    &.modal-body-round {
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    .metamask__logo {
      margin-top: 24px;
      margin-bottom: 62px;
    }
  }
  .modal-footer {
    background: rgb(51, 44, 63);
    border-top: none;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    justify-content: center;

    .btn {
      background: #aa3172;
      border-radius: 8px;
			padding: 12px 16px;
			border: none;
    }
  }
}
