//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $font-weight-medium;
  }
}

.nav-tabs-custom {
  // border-bottom: 2px solid $gray-300;
  border: none;
  .nav-item {
    position: relative;
    color: $text-700;
    .nav-link {
      border: none;
      background-color: transparent;
      color: $text-700;

      &::after {
        content: "";
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -2px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: #fff;
        &:after {
          transform: scale(1);
          border-bottom: 2px solid #fff;
        }
      }
    }
  }
}

.tabs-custom-justify {
  justify-content: center;
  border-bottom: 1px solid darken($gray-300, 2%);
  .nav-link {
    color: lighten($gray-600, 10%);
    padding: 0.5rem 0.5rem;
    margin: 0px 8px;
    font-weight: $font-weight-medium;
    border-bottom: 1px solid transparent;
    transition: all 0.3s;
    &.active,
    &:hover {
      border-bottom: 1px solid $primary;
      background-color: transparent !important;
      color: $primary;
      border-radius: 0;
      border-left: none !important;
      border-top: none !important;
      border-right: none !important;
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}
