.center-column-data {
  border-left: 1px solid #70697a;
  border-right: 1px solid #70697a;
}
.data-widget-row {
  flex-wrap: nowrap;
}

.search-wrapper {
  margin: 0;
}
.subheader-sm {
  display: none;
}
.header-tabs-lg {
  display: flex;
}

.header-main-row {
  display: flex;
}
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	display: block;
	text-align: left;
}
.logo {
  width: fit-content;
}

.chart-guide {
  width: 50%;
  margin: auto;
}

@media (max-width: 575px) {
  .center-column-data {
    border-top: 1px solid #70697a;
    border-bottom: 1px solid #70697a;
    border-left: none;
    border-right: none;
    margin: 15px 0;
    padding: 15px 0;
  }
  .header-main-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .data-widget-row {
    flex-wrap: wrap;
  }
  .subheader-sm {
    display: block;
    padding-left: 24px;
  }
  .header-tabs-lg {
    display: none !important;
  }
  .breadcrumb {
    margin-top: 30px !important;
  }
}

@media (max-width: 765px) {
  .search-wrapper {
    margin: 0;
    div {
      margin: 5px 0;
    }
    .search-icon-wrapper {
      width: 100%;
      span {
        right: 50%;
      }
    }
  }

  .chart-guide {
    width: 100%;
    margin: auto;
  }
}